import { TSize } from '@components/UiKit/types'

export enum Breakpoints {
  phone = 480,
  sm = 576,
  md = 768,
  lg = 962,
  xl = 1200,
  mHd = 1400,
  large = 1500,
  fullHd = 1900,
}

export enum BreakpointFlags {
  isMobile = Breakpoints.sm,
  isMd = Breakpoints.md,
  isTablet = Breakpoints.lg,
  isLaptop = Breakpoints.xl,
  isDesktop = Breakpoints.large,
  isFullHd = Breakpoints.fullHd,
}

export enum SizeByBreakpointFlag {
  isMobile = TSize.small,
  isTablet = TSize.small,
  isLaptop = TSize.compact,
  isDesktop = TSize.compact,
  isFullHd = TSize.spacious,
}

export const AVAILABLE_FUNCTIONALITY = {}
