import React, { useEffect } from 'react'

import { useLocation } from 'react-router'

import TopSection from './components/Hero'
import OpenPlatform from './components/OpenPlatform'
import Potential from './components/Potential'
import Ecosystem from './components/Ecosystem'
import FAQ from './components/FAQ'
import Products from './components/Products'
import WhitePaper from './components/WhitePaper'

function HomePage() {
  const { hash } = useLocation()

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        document
          .getElementById(hash.slice(1))
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 100)
    }
  }, [hash])

  return (
    <>
      <TopSection />
      <WhitePaper />
      <OpenPlatform />
      <Potential />
      <Ecosystem />
      <Products />
      <FAQ />
    </>
  )
}

export default HomePage
