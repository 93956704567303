import React from 'react'

import ComingSoon from '@containers/ComingSoon'
import HomePage from '@containers/Home'
import { isDevEnvironment } from '@constants/environments'
import Blog from '@containers/Blog'
import Roadmap from '@containers/Roadmap'

import routes from './path'

export default isDevEnvironment
  ? [
      {
        path: routes.home,
        element: <HomePage />,
      },
      {
        path: routes.comingSoon,
        element: <ComingSoon />,
      },
      {
        path: routes.blog.vineGoLive,
        element: <Blog />,
      },
      {
        path: routes.roadmap,
        element: <Roadmap />,
      },
    ]
  : [
      {
        path: routes.home,
        element: <HomePage />,
      },
      {
        path: routes.blog.vineGoLive,
        element: <Blog />,
      },
      {
        path: routes.comingSoon,
        element: <ComingSoon />,
      },
    ]
