import React from 'react'

import ProductItem from '../ProductItem'

function ProductList({ products }) {
  return (
    <ul>
      {products.cards.map((item) => (
        <ProductItem
          key={item.id}
          title={item.title}
          tag={item.tag}
          badge={item.badge}
          link={item.link}
          description={item.description}
        />
      ))}
    </ul>
  )
}

export default ProductList
