import React, { memo } from 'react'

import styles from './styles.module.scss'

interface Props {
  title: string
  text: string
}

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 56" fill="none">
      <path d="M4.89568e-06 -3.49691e-06L0 56L40 28L4.89568e-06 -3.49691e-06Z" fill="#F00606" />
    </svg>
  )
}

function UtilityItem({ title, text }: Readonly<Props>) {
  return (
    <div className={styles.item}>
      <div className={styles.item__title}>
        <Icon />
        {title}
      </div>
      <div className={styles.item__text}>{text}</div>
    </div>
  )
}

export default memo(UtilityItem)
