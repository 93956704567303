import React, { PropsWithChildren, memo } from 'react'

import cn from 'classnames'

import styles from './styles.module.scss'

interface Props {
  className?: string
}

function Container({ className, children }: PropsWithChildren<Props>) {
  return <div className={cn(styles.container, className)}>{children}</div>
}

export default memo(Container)
