import React from 'react'

import { useTranslation } from 'react-i18next'

import { externalLinks } from '@containers/ComingSoon/utils'
import SvgImage from '@components/SvgImage'
import ScrollUp from '@components/ScrollUp'
import vineLogo from '@assets/images/vine_logo.svg'

import styles from './footer.module.scss'

function Footer() {
  const { t } = useTranslation('nsFooter')

  return (
    <>
      <img src={vineLogo} alt="" style={{ width: '120vw', maxWidth: '120vw', margin: '0 -10vw' }} />
      <footer className={styles.footer}>
        <div className={styles.container}>
          <ScrollUp title={t('scrollUp')} />

          <div className={styles.flexContainer}>
            <div className={styles.socials}>
              {externalLinks.slice(1).map(({ name, icon, link, id }) => (
                <a href={link} className={styles.link} target="_blank" rel="noreferrer" key={id}>
                  <SvgImage
                    className={styles[name]}
                    src={icon}
                    color="#ffffff"
                    hoveredColor="#00d3f9"
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default React.memo(Footer)
