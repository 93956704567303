export const environments = {
  development: 'development',
  uat: 'uat',
  production: 'production',
}

export const { REACT_APP_STAGE } = process.env
export const isDevEnvironment = REACT_APP_STAGE === environments.development
export const isUatEnvironment = REACT_APP_STAGE === environments.uat
export const isProductionEnvironment = REACT_APP_STAGE === environments.production
export const isComingSoon = window.location.pathname.includes('coming-soon')
