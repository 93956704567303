import React from 'react'

import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import Container from '@components/Container'
import mix1 from '@assets/images/home/potential/mix-001.png'
import mix2 from '@assets/images/home/potential/mix-002.png'
import mix3 from '@assets/images/home/potential/mix-003.png'

import styles from './potential.module.scss'

function Potential() {
  const { t } = useTranslation('nsHome')

  const sectionTitle = t('potential.title', { returnObjects: true })
  const blocks = t('potential.blocks', { returnObjects: true })

  return (
    <section className={styles.section}>
      <Container>
        <div className={styles.title_wrapper}>
          <h1 className={styles.title}>
            <span className={styles.subtitle}>{sectionTitle[0]}</span>
            <br />
            {sectionTitle[1]}
          </h1>
        </div>
        <div className={styles.vine}>
          <div className={styles.vine_image}>
            <img src={mix1} alt="" />
          </div>
          <div className={styles.vine_text}>
            <h2 className={styles.vine_title}>{blocks[0].title}</h2>
            <p className={styles.vine_text__content}>{blocks[0].content}</p>
          </div>
        </div>
        <div className={cn(styles.vine, styles.reverse)}>
          <div className={styles.vine_text}>
            <h2 className={styles.vine_title}>{blocks[1].title}</h2>
            <p className={styles.vine_text__content}>{blocks[1].content}</p>
          </div>
          <div className={styles.vine_image}>
            <img src={mix2} alt="" />
          </div>
        </div>
        <div className={styles.vine}>
          <div className={styles.vine_image}>
            <img src={mix3} alt="" />
          </div>
          <div className={styles.vine_text}>
            <h2 className={styles.vine_title}>{blocks[2].title}</h2>
            <p className={styles.vine_text__content}>{blocks[2].content}</p>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Potential
