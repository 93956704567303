import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import Container from '@components/Container'
import Typography from '@components/UiKit/Typography'
import useCurrentWidth from '@hooks/useCurrentWidth'

import Item from './item'
import styles from './styles.module.scss'
import { IconChi, IconFresh, IconPeer } from './icons'

const ITEMS_MOBILE = [
  { icon: <IconPeer /> },
  { icon: <IconFresh /> },
  { icon: <IconChi /> },
  { icon: '' },
  { icon: '' },
  { icon: '' },
]

const ITEMS_DESKTOP = [
  { icon: <IconPeer /> },
  { icon: <IconFresh /> },
  { icon: '' },
  { icon: <IconChi /> },
  { icon: '' },
  { icon: '' },
]

function VenturePartners() {
  const { t } = useTranslation('nsRoadMap')
  const { isMobile, isMd, isTablet, isLaptop } = useCurrentWidth()
  const ITEMS = isMobile || isMd || isTablet || isLaptop ? ITEMS_MOBILE : ITEMS_DESKTOP
  const isShowMobileSubtitle = isMobile || isMd

  const { title, subTitle } = t('venturePartners', { returnObjects: true })

  return (
    <section className={styles.section}>
      <Container>
        {isShowMobileSubtitle ? (
          <>
            <Typography className={styles.subTitle__start} uppercase>
              {subTitle.start}
            </Typography>
            <Typography className={styles.subTitle__end} uppercase>
              {subTitle.end}
            </Typography>
          </>
        ) : (
          <Typography className={styles.subTitle} uppercase>
            {subTitle.start} {subTitle.end}
          </Typography>
        )}
        <Typography className={styles.title} uppercase component="h2">
          {title}
        </Typography>

        <div className={styles.content}>
          {ITEMS.map((item) => (
            <Item {...item} />
          ))}
        </div>
      </Container>
    </section>
  )
}

export default memo(VenturePartners)
