import React, { useState } from 'react'

import ReactDOMServer from 'react-dom/server'

interface ISvgImage extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  src: React.ReactElement<any, string | React.JSXElementConstructor<any>>
  className?: string
  color?: string
  hoveredColor?: string
}

const convertComponentToDataURL = (component: React.ReactElement) => {
  const svgString = ReactDOMServer.renderToStaticMarkup(component)
  const dataURL = `data:image/svg+xml,${encodeURIComponent(svgString)}`
  return dataURL
}

function SvgImage({ className, color, hoveredColor, alt, src, ...rest }: ISvgImage) {
  const [hovered, setHovered] = useState<boolean>(false)

  return (
    <img
      {...rest}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      src={convertComponentToDataURL(
        React.cloneElement(src, { color: hovered ? hoveredColor : color }),
      )}
      className={className}
      alt={alt}
    />
  )
}

export default React.memo(SvgImage)
