import React from 'react'

import Community from '@containers/Roadmap/components/Community'
import RoadmapSection from '@containers/Roadmap/components/Roadmap'
import Distribution from '@containers/Roadmap/components/Distribution'
import Utility from '@containers/Roadmap/components/Utility'
import VenturePartners from '@containers/Roadmap/components/VenturePartners'

function Roadmap() {
  return (
    <>
      <Community />
      <RoadmapSection />
      <Distribution />
      <Utility />
      <VenturePartners />
    </>
  )
}

export default Roadmap
