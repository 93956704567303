import React, { useLayoutEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'react-i18next'

import whitePaperAsset from '@assets/pdf/vineLitePaper.pdf'
import Container from '@components/Container'
import path from '@routing/path'

import styles from './white_paper.module.scss'
import { CardImage, NewsItemCard } from './components'

function WhitePaper() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(window.innerWidth < 1200)
  const [renderItems, setRenderItems] = useState(2)
  const { t, ready } = useTranslation('nsHome')

  const handleDownload = () => {
    const link = document.createElement('a')
    link.download = 'litepaper'
    link.href = whitePaperAsset
    link.click()
  }

  useLayoutEffect(() => {
    const handler = () => {
      let item = 1
      if (window.innerWidth > 960) {
        item = 3
      } else if (window.innerWidth > 767) {
        item = 2
      }

      setIsMobileOrTablet(window.innerWidth < 1200)
      setRenderItems(item)
    }

    window.addEventListener('resize', handler)

    handler()

    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  const cardImage = t('news.cards', { returnObjects: true })[0]
  const newsCards = t('news.cards', { returnObjects: true }).slice(1)

  const ANOUNCEMENT_ACTIONS = [
    {
      action: newsCards[0]?.link,
      actionHref: 'https://twitter.com/vine_dao',
      readMoreLink: path.blog.vineGoLive,
    },
    {
      action: newsCards[1]?.link,
      actionHref: 'https://vne.network/',
      readMoreLink: path.comingSoon,
    },
  ]

  const TITLE_LINKS = [
    {
      title: newsCards[0]?.subTitle,
      link: path.blog.vineGoLive,
    },
  ]

  return (
    <Container>
      <div className={styles.container}>
        {isMobileOrTablet ? (
          <Swiper
            slidesPerView={renderItems}
            spaceBetween={1}
            pagination={{
              clickable: true,
            }}
          >
            <SwiperSlide>
              <CardImage
                title={cardImage.title}
                link={cardImage.link}
                onDownload={handleDownload}
              />
            </SwiperSlide>
            {ready &&
              newsCards.map((item) => {
                const links = ANOUNCEMENT_ACTIONS.find((action) => action.action === item.link)
                const titleHref = TITLE_LINKS.find((title) => item.subTitle === title.title)

                return (
                  <SwiperSlide key={item.title}>
                    <div style={{ width: 240 }}>
                      <NewsItemCard
                        titleLink={titleHref?.link}
                        title={item.title}
                        subTitle={item.subTitle}
                        readMore={item.readMore}
                        readMoreLink={links?.readMoreLink}
                        link={item.link}
                        actionLink={links?.actionHref}
                      />
                    </div>
                  </SwiperSlide>
                )
              })}
          </Swiper>
        ) : (
          <>
            <CardImage title={cardImage.title} link={cardImage.link} onDownload={handleDownload} />
            {ready &&
              newsCards.map((item) => {
                const links = ANOUNCEMENT_ACTIONS.find((action) => action.action === item.link)
                const titleHref = TITLE_LINKS.find((title) => item.subTitle === title.title)

                return (
                  <NewsItemCard
                    titleLink={titleHref?.link}
                    title={item.title}
                    subTitle={item.subTitle}
                    readMore={item.readMore}
                    readMoreLink={links?.readMoreLink}
                    link={item.link}
                    actionLink={links?.actionHref}
                    key={item.title}
                  />
                )
              })}
          </>
        )}
      </div>
    </Container>
  )
}

export default WhitePaper
