import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import Container from '@components/Container'
import Typography from '@components/UiKit/Typography'

import Chart from './Chart'
import styles from './distribution.module.scss'

const detailsList = [
  { id: 1, text: 'NAME - VINE' },
  { id: 2, text: 'TICKER - VNE' },
  { id: 3, text: 'SUPPLY - 8.000.000.000' },
  { id: 4, text: 'MAX SUPPLY - ∞' },
  { id: 5, text: 'OUTSTANDING - $1.575.000.000' },
  { id: 6, text: 'MARKET CAP - $1.575.000.000' },
]

function Distribution() {
  const { t } = useTranslation('nsRoadMap')

  const { title, subTitle, details } = t('distribution', { returnObjects: true })

  return (
    <section className={styles.section}>
      <Container>
        <Typography className={styles.title} uppercase component="h2">
          {title}
        </Typography>
        <Typography className={styles.subTitle} uppercase>
          {subTitle}
        </Typography>
        <div className={styles.statistic}>
          <div className={styles.details}>
            <span className={styles.details_title}>{details.title}</span>
            <ul className={styles.details_list}>
              {detailsList.map(({ id, text }) => (
                <li key={id}>{text}</li>
              ))}
            </ul>
          </div>
          <Chart />
        </div>
      </Container>
    </section>
  )
}

export default memo(Distribution)
