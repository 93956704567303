import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel } from 'swiper/modules'
import cn from 'classnames'

import Container from '@components/Container'
import { useSwiperHeight } from '@containers/Roadmap/components/Roadmap/roadmap.utils'

import styles from './roadmap.module.scss'

function Roadmap() {
  const { t } = useTranslation('nsRoadMap')
  const { title, subTitle, phase, month, text, slider } = t('roadmap', { returnObjects: true })
  const { height } = useSwiperHeight()
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <p className={styles.title}>
          {title.start} <span className={styles.title_middle}>{title.middle}</span> {title.end}
        </p>
        <p className={styles.subTitle}>{subTitle}</p>
        <p className={styles.phase}>{phase}</p>

        <div className={styles.swiper_container}>
          <Swiper
            direction="vertical"
            slidesPerView={5}
            mousewheel={{
              forceToAxis: true,
              releaseOnEdges: true,
            }}
            modules={[Mousewheel]}
            height={height}
            centeredSlides
            initialSlide={2}
          >
            {slider.map((txt) => (
              <SwiperSlide key={txt}>
                {({ isActive, isPrev, isNext }) => (
                  <div
                    className={cn({
                      [styles.row]: true,
                      [styles.row_chosen]: isActive,
                      [styles.row_near]: isPrev || isNext,
                      [styles.row_utmost]: !isActive && !isPrev && !isNext,
                    })}
                  >
                    {txt}
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <p className={styles.date}>{month} 01, 2024</p>
        <p className={styles.time}>00:00 UTC</p>
        <p className={styles.description}>{text}</p>
      </Container>
    </section>
  )
}

export default memo(Roadmap)
