import React from 'react'

import cn from 'classnames'

import styles from './badge.module.scss'

interface BadgeProps {
  title: string
  className?: string
}

export default function Badge({ title, className }: BadgeProps) {
  return <p className={cn(styles.container, className)}>{title}</p>
}
