import React, { memo } from 'react'

import styles from './styles.module.scss'

interface Props {
  icon?: string | JSX.Element
}

function UtilityItem({ icon }: Readonly<Props>) {
  return <div className={styles.item}>{icon}</div>
}

export default memo(UtilityItem)
