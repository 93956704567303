import React, { useState, useEffect, useRef } from 'react'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import UltraImg from '@assets/images/hero/responsive/ultra.png'
import UltraMinImg from '@assets/images/hero/responsive/ultra-min.png'
import FullImg from '@assets/images/hero/responsive/full.png'
import FullMinImg from '@assets/images/hero/responsive/full-min.png'
import LaptopImg from '@assets/images/hero/responsive/laptop.png'
import LaptopMinImg from '@assets/images/hero/responsive/laptop-min.png'
import TabletImg from '@assets/images/hero/responsive/tablet.png'
import TabletMinImg from '@assets/images/hero/responsive/tablet-min.png'
import MobileImg from '@assets/images/hero/responsive/mobile.png'
import MobileMinImg from '@assets/images/hero/responsive/mobile-min.png'

import ProgressiveImage from './ProgressiveImage'
import styles from './hero.module.scss'

function Hero() {
  const [scrollY, setScrollY] = useState(0)
  const [yPos, setScrollPosition] = useState(0)
  const el = useRef<HTMLDivElement>(null)

  const { t } = useTranslation('nsHome')
  const hero = t('hero', { returnObjects: true })

  const onScroll = () => {
    const toTop = el?.current?.offsetTop || 0
    const scrollY = window?.scrollY
    const windowHeight = window?.screen?.height
    const position = scrollY - toTop
    setScrollY(scrollY)

    if (toTop < scrollY + windowHeight) {
      setScrollPosition(position)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const speed = yPos / 30
  const opacity = 1 - yPos / 1500

  return (
    <section className={styles.section}>
      <div style={{ transform: `translateY(${scrollY * 0.1}px)` }}>
        <ProgressiveImage
          ultraImg={UltraImg}
          ultraMinImg={UltraMinImg}
          fullImg={FullImg}
          fullMinImg={FullMinImg}
          laptopImg={LaptopImg}
          laptopMinImg={LaptopMinImg}
          tabletImg={TabletImg}
          tabletMinImg={TabletMinImg}
          mobileImg={MobileImg}
          mobileMinImg={MobileMinImg}
          alt="background"
        />
        <div className={styles.title_container}>
          <div ref={el} style={{ transform: `translate3d(0, ${speed}px, 0)`, opacity }}>
            <h1 className={cn(styles.title, styles.title_first)}>{hero.heroFirst}</h1>
            <h2 className={cn(styles.title, styles.title_second)}>{hero.heroSecond}</h2>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
