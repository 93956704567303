/* eslint-disable max-len */
import React from 'react'

import cn from 'classnames'

import { TIconProps } from '@components/Icons/types'

import styles from '../styles.module.scss'

export default function Download({ width = '22', size, color = '#fff' }: TIconProps) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn({ [styles.scaled]: size === 'sm' })}
      color={color}
    >
      <g clipPath="url(#clip0_12566_341)">
        <path
          d="M2.44444 20.1884L2.44444 3.07726L19.5556 3.07726L19.5556 11.6328L22 11.6328L22 3.07726C22 1.72059 20.9 0.632812 19.5556 0.632812L2.44444 0.632812C1.1 0.632812 -4.75483e-08 1.72059 -1.0685e-07 3.07726L-8.548e-07 20.1884C-9.13568e-07 21.5328 1.1 22.6328 2.44444 22.6328L11 22.6328L11 20.1884L2.44444 20.1884ZM22 14.0773L19.5556 14.0773L19.5556 18.465L7.54111 6.45059L5.81778 8.17392L17.8322 20.1884L13.4444 20.1884L13.4444 22.6328L22 22.6328L22 14.0773Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_12566_341">
          <rect width="22" height="22" fill="white" transform="translate(22 0.632812) rotate(90)" />
        </clipPath>
      </defs>
    </svg>
  )
}
