import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import Container from '@components/Container'
import Typography from '@components/UiKit/Typography'

import Item from './item'
import styles from './styles.module.scss'

function Utility() {
  const { t } = useTranslation('nsRoadMap')

  const { title, subTitle, items, text } = t('utility', { returnObjects: true })

  return (
    <section className={styles.section}>
      <Container>
        <Typography className={styles.title} uppercase component="h2">
          {title}
        </Typography>
        <Typography className={styles.subTitle} uppercase>
          {subTitle}
        </Typography>
        <div className={styles.content}>
          {items.map((item) => (
            <Item {...item} />
          ))}
        </div>

        <div className={styles.text}>
          {text.start} <span>{text.middle}</span> {text.end}
        </div>
      </Container>
    </section>
  )
}

export default memo(Utility)
