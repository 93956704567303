import React from 'react'

import Instagram from '@components/Icons/Share/Instagram'
import XSocial from '@components/Icons/Share/XSocial'
import Telegram from '@components/Icons/Share/Telegram'
import { TIconProps } from '@components/Icons/types'
import Discord from '@components/Icons/Share/Discord'

export const externalLinks = [
  {
    id: 1,
    name: 'Instagram',
    icon: <Instagram />,
    link: 'https://www.instagram.com/peernewworld',
  },
  {
    id: 2,
    name: 'Telegram',
    icon: <Telegram />,
    link: 'https://t.me/vinedao',
  },
  {
    id: 3,
    name: 'XSocial',
    icon: <XSocial />,
    link: 'https://twitter.com/vine_dao',
  },
  {
    id: 4,
    name: 'Discord',
    icon: <Discord />,
    link: 'https://discord.gg/6NzyMY3rpn',
  },
]

export const renderIcon = (name: string, width: TIconProps['width']) => {
  if (name.toLowerCase() === 'xsocial') {
    return <XSocial width={width} />
  }
  if (name.toLowerCase() === 'telegram') {
    return <Telegram width={width} />
  }
  if (name.toLowerCase() === 'instagram') {
    return <Instagram width={width} />
  }
  return null
}
