import React, { useEffect, useState } from 'react'

import cn from 'classnames'
import { NavLink } from 'react-router-dom'

import XSocilaImage from '@assets/images/x_social.svg'
import ROUTING from '@routing/path'
import { isDevEnvironment } from '@constants/environments'

import LangSelect from './LangSelect'
import styles from './header.module.scss'

const navLinks = [
  {
    name: 'roadmap',
    to: ROUTING.roadmap,
  },
  {
    name: 'developers',
    to: ROUTING.home,
  },
  {
    name: 'team',
    to: ROUTING.home,
  },
]

function Hamburger() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  return (
    <div className={styles.hamburger}>
      <div
        className={cn(styles.toggleButton, { [styles.active]: isOpen })}
        onClick={() => setIsOpen(!isOpen)}
      />

      <div className={cn(styles.hamburgerMenu, { [styles.isOpen]: isOpen })}>
        {isDevEnvironment && (
          <nav className={cn(styles.navbar, styles.mobile)}>
            {navLinks.map((link) => (
              <NavLink className={styles.link} to={link.to} key={link.name}>
                {link.name}
              </NavLink>
            ))}
          </nav>
        )}

        <a
          className={cn(styles.button, styles.mobile)}
          href="https://twitter.com/vine_dao"
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.icon}>
            <img src={XSocilaImage} alt="XSocial" />
          </div>
          Follow Us
        </a>

        <LangSelect className={styles.mobile} />
      </div>
    </div>
  )
}

export default Hamburger
