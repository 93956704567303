import React from 'react'

import Link from '@components/Icons/Link'
import Discord from '@components/Icons/Share/Discord'
import XSocial from '@components/Icons/Share/XSocial'
import Telegram from '@components/Icons/Share/Telegram'

export const externalLinks = [
  {
    id: 1,
    name: 'X',
    icon: <XSocial />,
  },
  {
    id: 2,
    name: 'Telegram',
    icon: <Telegram />,
  },
  {
    id: 3,
    name: 'Discord',
    icon: <Discord />,
  },
  {
    id: 4,
    name: 'Link',
    icon: <Link />,
  },
]

export const telegramShareBaseUrl = 'https://t.me/share/ur'
export const postTextX = 'Vine mainnet go live!'
export const xShareBaseUrlUrl = 'https://twitter.com/intent/tweet'
export const discordShareUrl = 'https://discord.com/channels/@me'
