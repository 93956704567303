import React from 'react'

import styles from './scroll_up.module.scss'

function ScrollUp({ title }) {
  const scrollUpHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  return (
    <div className={styles.container}>
      <div className={styles.line} />
      <p onClick={scrollUpHandler}>{title}</p>
    </div>
  )
}

export default ScrollUp
