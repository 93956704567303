import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import cn from 'classnames'

import vineLogo from '@assets/images/vine_logo.svg'
import SvgImage from '@components/SvgImage'
import path from '@routing/path'

import styles from './coming_soon.module.scss'
import { externalLinks } from './utils'

function ComingSoon() {
  const [isContentLoaded, setIsContentLoaded] = useState(false)
  return (
    <div className={styles.section}>
      <div className={styles.animated} />
      <div className={cn(styles.container, { [styles.visible]: isContentLoaded })}>
        <div className={styles.topSection}>
          <Link to={path.home}>
            <img src={vineLogo} alt="vine logo" onLoad={() => setIsContentLoaded(true)} />
          </Link>
        </div>

        <div className={styles.comingSoonBanner}>
          <h2 className={styles.quatro}>COMING</h2>
          <h2 className={styles.rockSalt}>SOON!</h2>
        </div>

        <div className={styles.socials}>
          {externalLinks.slice(1).map(({ name, icon, link }) => (
            <a href={link} className={styles.link} target="_blank" rel="noreferrer" key={name}>
              <SvgImage
                className={styles[name]}
                src={icon}
                color="#ffffff"
                hoveredColor="#00d3f9"
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ComingSoon
