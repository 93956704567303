import React, { memo, useLayoutEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'react-i18next'

import Typography from '@components/UiKit/Typography'
import Container from '@components/Container'
import ROUTING from '@routing/path'

import Card from './Card'
import styles from './styles.module.scss'
import { IconMoon, IconPeer, IconPlaya } from './icons'

const CARDS = [
  {
    id: 1,
    icon: <IconPeer />,
    href: 'https://peer.inc/',
    site: 'www.peer.inc',
  },
  {
    id: 2,
    icon: <IconPlaya />,
    href: 'https://playa.net/',
    site: 'www.PLAYA.NET',
  },
  {
    id: 3,
    icon: <IconMoon />,
    to: ROUTING.comingSoon,
    site: 'COMING SOON',
  },
]

function Ecosystem() {
  const [renderItems, setRenderItems] = useState(2)
  const { t } = useTranslation('nsHome')
  const ecosystem = t('ecosystem', { returnObjects: true })

  useLayoutEffect(() => {
    const handler = () => {
      let item = 1
      if (window.innerWidth > 960) {
        item = 3
      } else if (window.innerWidth > 767) {
        item = 2
      }

      setRenderItems(item)
    }

    window.addEventListener('resize', handler)

    handler()

    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  return (
    <section className={styles.section}>
      <Container>
        <div className={styles.title_wrap}>
          <div>
            <Typography uppercase component="h2">
              {ecosystem.title}
            </Typography>
            <Typography uppercase className={styles.sub_title}>
              {ecosystem.subtitle}
            </Typography>
          </div>
        </div>
      </Container>

      <div className={styles.container_slider}>
        <Container className={styles.slider_container}>
          <div className={styles.container_slider__inner}>
            <Swiper
              slidesPerView={renderItems}
              spaceBetween={1}
              pagination={{
                clickable: true,
              }}
            >
              {CARDS.map((item) => (
                <SwiperSlide key={item.id}>
                  <Card
                    icon={item.icon}
                    name={ecosystem[`card_${item.id}_name`]}
                    href={item.href}
                    to={item.to}
                    site={item.site === 'COMING SOON' ? ecosystem.coming_soon : item.site}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Container>
      </div>
    </section>
  )
}

export default memo(Ecosystem)
