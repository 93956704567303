import React, { memo } from 'react'

import { NavLink } from 'react-router-dom'

import styles from './styles.module.scss'

interface Props {
  icon: JSX.Element
  name: string
  href?: string
  site: string
  to?: string
}

function EcosystemCard({ icon, name, href, site, to }: Readonly<Props>) {
  const siteOptions: {
    elem: string
    props: {
      className: string
      target?: string
      href?: string
      rel?: string
    }
    children: string
  } = {
    elem: 'span',
    props: {
      className: styles.card__link,
    },
    children: site,
  }

  if (href) {
    siteOptions.elem = 'a'
    siteOptions.props.href = href
    siteOptions.props.target = '_blank'
    siteOptions.props.rel = 'noreferrer'
  }

  return (
    <div className={styles.card}>
      <div className={styles.card__icon}>{icon}</div>
      <div className={styles.card__footer}>
        <div className={styles.card__name}>{name}</div>
        {(to && React.createElement(NavLink, { ...siteOptions.props, to }, siteOptions.children)) ||
          React.createElement(siteOptions.elem, siteOptions.props, siteOptions.children)}
      </div>
    </div>
  )
}

export default memo(EcosystemCard)
