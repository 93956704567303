import React from 'react'

import Typography from '@components/UiKit/Typography'
import IconNewTab from '@assets/icons/new_tab.svg'

import styles from './item.module.scss'

function ProductItem({ title, tag, badge, link, description }) {
  return (
    <li className={styles.item}>
      <div className={styles.item_header}>
        <div className={styles.title_group}>
          <span className={styles.title_group__tag}>{tag}</span>
          <Typography uppercase component="h3" className={styles.title_group__title}>
            {title}
            {badge && <span className={styles.title_group__badge}>{badge}</span>}
          </Typography>
        </div>
        <div className={styles.icons_group}>
          {link && (
            <a href={link} target="_blank" rel="noreferrer noopener">
              <img src={IconNewTab} alt="new tab" className={styles.new_tab} />
            </a>
          )}
        </div>
      </div>
      <Typography uppercase component="p" className={styles.item_description}>
        {description}
      </Typography>
    </li>
  )
}

export default ProductItem
