import React, { useState } from 'react'

function ProgressivePicture({
  ultraImg,
  ultraMinImg,
  fullImg,
  fullMinImg,
  laptopImg,
  laptopMinImg,
  tabletImg,
  tabletMinImg,
  mobileImg,
  mobileMinImg,
  alt,
  ...props
}) {
  const [highResLoaded, setHighResLoaded] = useState(false)

  const handleHighResLoad = () => {
    setHighResLoaded(true)
  }

  return (
    <picture>
      <source media="(min-width: 2400px)" srcSet={highResLoaded ? ultraImg : ultraMinImg} />
      <source media="(min-width: 1900px)" srcSet={highResLoaded ? fullImg : fullMinImg} />
      <source media="(min-width: 1200px)" srcSet={highResLoaded ? laptopImg : laptopMinImg} />
      <source media="(min-width: 768px)" srcSet={highResLoaded ? tabletImg : tabletMinImg} />
      <source media="(max-width: 768px)" srcSet={highResLoaded ? mobileImg : mobileMinImg} />
      <img
        src={highResLoaded ? fullImg : fullMinImg}
        alt={alt}
        onLoad={handleHighResLoad}
        {...props}
      />
    </picture>
  )
}

export default ProgressivePicture
