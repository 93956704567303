import React, { useState } from 'react'

import cn from 'classnames'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Container from '@components/Container'
import path from '@routing/path'
import SvgImage from '@components/SvgImage'

import styles from './blog.module.scss'
import {
  discordShareUrl,
  externalLinks,
  postTextX,
  telegramShareBaseUrl,
  xShareBaseUrlUrl,
} from './utils'

function Blog() {
  const [isFade, setIsFade] = useState(false)

  const { t } = useTranslation('nsBlog')
  const articles = t('vineMainnetGoLive.articles', { returnObjects: true })

  const shareClickHandler = async (name: string) => {
    const urlToShare = window.location.href

    if (name === 'Link') {
      await navigator.clipboard.writeText(urlToShare)
      setIsFade(true)
      return
    }

    let linkToOpen = ''
    const telegramShareUrl = `${telegramShareBaseUrl}?url=${encodeURIComponent(urlToShare)}`
    const xShareUrl = `${xShareBaseUrlUrl}?url=${encodeURIComponent(
      urlToShare,
    )}&text=${encodeURIComponent(postTextX)}`

    switch (name) {
      case 'Telegram':
        linkToOpen = telegramShareUrl
        break

      case 'Discord':
        await navigator.clipboard.writeText(urlToShare)
        linkToOpen = discordShareUrl
        break

      case 'X':
        linkToOpen = xShareUrl
        break
      default:
        break
    }

    if (linkToOpen) {
      window.open(linkToOpen, '_blank')
    }
  }

  return (
    <Container>
      <div className={styles.flexContainer}>
        <div className={styles.titleContainer}>
          <Link to={path.home} className={styles.backBtn}>
            {t('backHome')}
          </Link>
          <h2 className={styles.blogTitle}>{t('vineMainnetGoLive.title')}</h2>
          <div className={styles.announce}>01/01/2024</div>
        </div>

        <div>
          <p className={styles.subtitle}>{t('vineMainnetGoLive.subTitle')}</p>
          <div className={styles.articles}>
            {articles.map((article) => (
              <div key={article.title} className={styles.articleItem}>
                <h5 className={styles.articleItemTitle}>{article.title}</h5>
                <p className={styles.blogRegularText}>{article.content}</p>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.shareArticle}>
          <span className={styles.blogRegularText}>{t('share')}</span>
          <div className={styles.icons}>
            <div
              className={cn(styles.copied, {
                [styles.fade]: isFade,
              })}
              onAnimationEnd={() => setIsFade(false)}
            >
              copied
            </div>
            {externalLinks.map(({ name, icon }) => {
              const handler = () => shareClickHandler(name)
              return (
                <div key={name} onClick={handler} className={styles.iconWrapper}>
                  <SvgImage
                    className={styles[name]}
                    src={icon}
                    color="#ffffff"
                    hoveredColor="#00d3f9"
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Blog
