import React from 'react'

import { useTranslation } from 'react-i18next'

import Container from '@components/Container'
import Typography from '@components/UiKit/Typography'

import ProductList from './ProductList'
import styles from './products.module.scss'

export default function Products() {
  const { t } = useTranslation('nsHome')
  const products = t('products', { returnObjects: true })

  return (
    <section id="productSection" className={styles.section}>
      <Container>
        <div className={styles.title_wrap}>
          <Typography uppercase component="h2">
            {products.title}
          </Typography>
        </div>
        <ProductList products={products} />
      </Container>
    </section>
  )
}
