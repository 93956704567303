/* eslint-disable max-len */
import React from 'react'

export function IconPeer() {
  return (
    <svg
      width="295"
      height="84"
      viewBox="0 0 295 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12566_566)">
        <path
          d="M129.635 4.37828C124.622 1.74491 118.984 0.414062 112.751 0.414062C106.518 0.414062 100.463 1.82278 95.3359 4.60481C90.1949 7.40807 86.1459 11.2732 83.189 16.2001C80.232 21.1271 78.75 26.7903 78.75 33.1826C78.75 39.5749 80.2462 45.1035 83.2528 50.0376C86.2452 54.9645 90.4998 58.8579 95.9953 61.6966C101.491 64.5353 107.866 65.9511 115.134 65.9511C120.899 65.9511 125.997 65.0662 130.415 63.2894C133.918 61.8807 136.939 59.9977 139.506 57.6404C140.626 56.5998 140.683 54.8513 139.655 53.7399L133.407 46.9865C132.429 45.9247 130.805 45.8114 129.663 46.6963C128.061 47.9351 126.352 48.912 124.537 49.6341C121.97 50.6605 118.991 51.1702 115.588 51.1702C111.794 51.1702 108.49 50.4835 105.689 49.1031C102.888 47.7227 100.711 45.7052 99.1792 43.0719C98.3425 41.6419 97.7468 40.0845 97.3639 38.3998H143.058C144.491 38.3998 145.689 37.3096 145.81 35.8796V35.8513C145.888 34.9523 145.937 34.1382 145.937 33.4303C145.937 26.5779 144.477 20.674 141.555 15.74C138.634 10.8131 134.663 7.02581 129.649 4.38536H129.621L129.635 4.37828ZM104.519 16.4408C106.93 15.0604 109.709 14.3738 112.872 14.3738C116.035 14.3738 118.8 15.0675 121.175 16.4408C123.551 17.8212 125.402 19.7325 126.749 22.1819C127.635 23.8029 128.231 25.6152 128.529 27.6185H97.1441C97.449 25.6435 98.0305 23.8454 98.8956 22.2314C100.243 19.7467 102.122 17.8212 104.519 16.4408Z"
          fill="black"
        />
        <path
          d="M204.416 4.37828C199.403 1.74491 193.766 0.414062 187.533 0.414062C181.3 0.414062 175.244 1.82278 170.117 4.60481C164.976 7.40807 160.927 11.2732 157.97 16.2001C155.013 21.1271 153.531 26.7903 153.531 33.1826C153.531 39.5749 155.027 45.1035 158.034 50.0376C161.026 54.9645 165.281 58.8579 170.777 61.6966C176.272 64.5353 182.647 65.9511 189.915 65.9511C195.68 65.9511 200.779 65.0662 205.196 63.2894C208.699 61.8807 211.72 59.9977 214.287 57.6404C215.407 56.5998 215.464 54.8513 214.436 53.7399L208.189 46.9865C207.21 45.9247 205.586 45.8114 204.445 46.6963C202.842 47.9351 201.133 48.912 199.318 49.6341C196.751 50.6605 193.773 51.1702 190.369 51.1702C186.575 51.1702 183.271 50.4835 180.47 49.1031C177.662 47.7227 175.492 45.7052 173.96 43.0719C173.124 41.6419 172.528 40.0845 172.145 38.3998H217.84C219.272 38.3998 220.47 37.3096 220.591 35.8796V35.8513C220.669 34.9523 220.719 34.1382 220.719 33.4303C220.719 26.5779 219.258 20.674 216.336 15.74C213.415 10.8131 209.444 7.02581 204.431 4.38536H204.402L204.416 4.37828ZM179.3 16.4408C181.711 15.0604 184.491 14.3738 187.653 14.3738C190.816 14.3738 193.581 15.0675 195.957 16.4408C198.332 17.8212 200.183 19.7325 201.53 22.1819C202.417 23.8029 203.012 25.6152 203.31 27.6185H171.918C172.223 25.6435 172.805 23.8454 173.67 22.2314C175.017 19.7467 176.896 17.8212 179.293 16.4408H179.307H179.3Z"
          fill="black"
        />
        <path
          d="M269.32 0C246.678 0 228.312 18.3203 228.312 40.9376V61.6648C228.312 63.1868 229.553 64.4256 231.078 64.4256H244.593C246.118 64.4256 247.359 63.1868 247.359 61.6648V40.9376C247.359 28.8397 257.194 19.0141 269.32 19.0141H273.34C274.865 19.0141 276.106 17.7753 276.106 16.2533V2.76079C276.106 1.23882 274.865 0 273.34 0H269.32Z"
          fill="black"
        />
        <path
          d="M55.0971 4.01799C49.7292 1.6253 43.3331 0.421875 35.9089 0.421875H2.76549C1.24093 0.421875 0 1.65361 0 3.18267V80.4637C0 81.9856 1.24093 83.2245 2.76549 83.2245H16.4228C17.9473 83.2245 19.1883 81.9856 19.1883 80.4637V63.1556C19.1883 61.6336 20.4292 60.3948 21.9538 60.3948H35.8876C43.3119 60.3948 49.708 59.1914 55.0759 56.7845C60.4437 54.3777 64.592 50.9302 67.5135 46.4351C70.435 41.94 71.8957 36.6166 71.8957 30.4721C71.8957 24.3275 70.435 18.8767 67.5135 14.3816C64.592 9.88644 60.4437 6.43899 55.0759 4.03214H55.09L55.0971 4.01799ZM48.1054 40.9772C45.1839 43.4973 40.7591 44.7645 34.831 44.7645H19.1954V16.031H34.831C40.7591 16.031 45.1768 17.2981 48.1054 19.8182C51.0269 22.3383 52.4876 25.8849 52.4876 30.465C52.4876 35.0451 51.0269 38.4713 48.1054 40.9914V40.9772Z"
          fill="black"
        />
        <path
          d="M293.365 52.984L287.082 49.933C285.657 49.1897 284.487 48.0217 283.75 46.5917L280.693 40.3056C280.034 38.9606 278.119 38.9606 277.46 40.3056L274.489 46.4148C273.73 47.9863 272.468 49.2534 270.894 50.0109L264.788 52.977C264.115 53.3097 263.774 53.9539 263.781 54.6051C263.781 55.2493 264.115 55.9006 264.788 56.2333L271.071 59.2843C272.496 60.0276 273.666 61.1956 274.404 62.6256L277.46 68.9117C278.119 70.2567 280.034 70.2567 280.693 68.9117L283.665 62.8026C284.423 61.231 285.685 59.9639 287.26 59.2064L293.365 56.2404C294.039 55.9077 294.379 55.2635 294.372 54.6122C294.372 53.968 294.039 53.3168 293.365 52.984Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_12566_566">
          <rect width="294.376" height="83.2202" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function IconPlaya() {
  return (
    <svg
      width="295"
      height="61"
      viewBox="0 0 295 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M238.792 60.3191L237.586 59.1127V8.61702L246.203 0H285.755L294.372 8.61702V59.1127L293.166 60.3191H280.93L279.723 59.1127V38.9489H252.235V59.1127L251.028 60.3191H238.792ZM252.235 26.0234H279.723V15.7691L276.88 12.9255H255.165L252.235 15.7691V26.0234Z"
        fill="black"
      />
      <path
        d="M176.469 19.1298V1.20638L177.675 0H189.911L191.118 1.20638V15.8553L204.905 33.0894L218.606 15.8553V1.20638L219.812 0H232.049L233.255 1.20638V19.1298L232.652 20.6808L212.229 44.6362V59.1127L211.023 60.3191H198.787L197.58 59.1127V44.6362L177.072 20.6808L176.469 19.1298Z"
        fill="black"
      />
      <path
        d="M116.597 60.3191L115.391 59.1127V8.61702L124.008 0H163.56L172.177 8.61702V59.1127L170.97 60.3191H158.734L157.528 59.1127V38.9489H130.04V59.1127L128.833 60.3191H116.597ZM130.04 26.0234H157.528V15.7691L154.684 12.9255H132.969L130.04 15.7691V26.0234Z"
        fill="black"
      />
      <path
        d="M64.4564 0H76.6925L77.8989 1.20638V47.3936H109.782L111.074 48.6L109.523 59.1127L108.231 60.3191H64.4564L63.25 59.1127V1.20638L64.4564 0Z"
        fill="black"
      />
      <path
        d="M0 59.1127V1.20638L1.20638 0H48.1691L56.7862 8.61702V37.3117L48.1691 45.9287H14.6489V59.1127L13.4425 60.3191H1.20638L0 59.1127ZM14.6489 33.0032H39.2074L42.1372 30.0734V15.8553L39.2074 12.9255H14.6489V33.0032Z"
        fill="black"
      />
    </svg>
  )
}

export function IconWonka() {
  return (
    <svg
      width="295"
      height="87"
      viewBox="0 0 295 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12566_572)">
        <path
          d="M281.587 76.2751C279.763 76.2751 279.054 75.5471 279.054 73.6797V60.766H292.606V50.9541H279.054V40.9524H279.016V23.9241C279.016 20.4456 278.091 17.6825 276.283 15.7327C274.437 13.6912 271.372 12.6562 267.174 12.6562H236.649V22.4681H264.767C266.591 22.4681 267.3 23.1961 267.3 25.0635V25.3167H244.249C240.509 25.3167 237.571 26.3074 235.522 28.2634C233.454 30.2353 232.406 33.0143 232.406 36.5212C232.406 40.0282 233.454 42.8736 235.522 44.8423C237.574 46.7952 240.509 47.789 244.249 47.789H267.338V50.9541H257.902V60.766H267.338V74.8191C267.338 78.133 268.326 80.8708 270.283 82.9725C272.253 85.0393 275.289 86.0869 279.304 86.0869H294.376V76.2751H281.584H281.587ZM267.3 35.1286V37.9772H245.895C245.347 37.9772 245.1 37.8063 244.948 37.6512C244.733 37.379 244.629 37.0086 244.629 36.5212C244.629 36.0338 244.727 35.6983 244.929 35.4767C245.078 35.3153 245.325 35.1286 245.895 35.1286H267.3Z"
          fill="black"
        />
        <path
          d="M227.528 74.3988C226.328 73.1296 225.72 71.0565 225.72 68.2427C225.72 65.4289 226.328 63.2924 227.515 62.0359C228.757 60.7603 230.53 60.14 232.939 60.14H255.421V50.3281H232.939C226.986 50.3281 222.25 51.8632 218.868 54.8859C215.432 57.9086 213.688 62.403 213.688 68.2427C213.688 74.0823 215.429 78.5293 218.868 81.5995C222.259 84.581 226.993 86.0939 232.939 86.0939H256.054V76.2821H232.939C230.53 76.2821 228.757 75.6617 227.528 74.3988Z"
          fill="black"
        />
        <path d="M211.161 50.9609H199.445V86.0937H211.161V50.9609Z" fill="black" />
        <path
          d="M184.976 50.9609H158.758V86.0937H170.6V60.8361H182.569C184.393 60.8361 185.102 61.5451 185.102 63.3682V70.2681H196.945V62.2287C196.945 58.9085 195.954 56.1865 194.01 54.1482C192.037 52.0339 189 50.9609 184.976 50.9609Z"
          fill="black"
        />
        <path
          d="M108.623 63.1152H87.3442C86.7362 63.1152 86.4449 62.8936 86.2771 62.7259C86.1821 62.6309 85.8876 62.3366 85.8876 61.5326C85.8876 60.466 86.2264 60.14 87.3442 60.14H117.045V50.3281H86.9009C82.8827 50.3281 79.7226 51.3315 77.5093 53.3065C75.296 55.2784 74.1719 58.1111 74.1719 61.7225C74.1719 65.3339 75.296 68.1002 77.522 70.0848C79.691 71.9712 82.8479 72.927 86.9009 72.927H106.976C107.764 72.927 108.369 73.0473 108.369 74.5729C108.369 76.0985 107.764 76.2821 106.976 76.2821H74.6785V86.0939H108.623C112.213 86.0939 115.054 85.0494 117.074 82.9794C119.072 80.8873 120.085 77.9944 120.085 74.383C120.085 70.7716 119.059 67.9863 117.055 66.0809C115.085 64.1122 112.248 63.1152 108.623 63.1152Z"
          fill="black"
        />
        <path d="M71.0126 50.9609H59.2969V86.0937H71.0126V50.9609Z" fill="black" />
        <path
          d="M48.8864 44.2958C45.6155 41.5168 41.4105 39.6842 36.3284 38.817L42.7309 33.6578L60.2064 47.7932H70.2724L75.6205 0H62.9422L59.2691 31.9392L49.0606 24.0675V0H36.3949V24.0011L26.066 31.9392L22.4563 0H9.77792L14.0621 38.2821L8.73933 38.2979H0V86.0911H29.0044C37.4651 86.0911 44.1558 84.0528 48.8864 80.0299C53.6772 75.9564 56.109 69.9459 56.109 62.1629C56.109 54.3798 53.6804 48.3693 48.8864 44.2958ZM28.3078 48.996C33.2949 48.996 37.0883 50.0658 39.593 52.1864C42.0216 54.2026 43.2533 57.5608 43.2533 62.1629C43.2533 66.7649 42.0184 70.0883 39.5866 72.1488C37.0915 74.2599 33.2949 75.3297 28.3078 75.3297H12.6657V48.996H28.3078Z"
          fill="black"
        />
        <path
          d="M218.249 47.7916H234.138L214.478 23.9267L234.144 0.125H218.249L203.367 18.546H190.166V0.125H177.5V47.7916H190.166V29.3074H203.303L210.671 38.4229H199.389V48.1081H211.232V39.116L218.249 47.7916Z"
          fill="black"
        />
        <path
          d="M158.204 22.4681C160.028 22.4681 160.737 23.1771 160.737 25.0002V47.789H172.453V23.9241C172.453 20.607 171.462 17.885 169.527 15.8562C167.595 13.7324 164.553 12.6562 160.484 12.6562H130.783V47.789H130.795V50.9541H121.359V60.766H130.795V74.8191C130.795 78.133 131.783 80.8708 133.743 82.9725C135.716 85.0393 138.749 86.0869 142.768 86.0869H157.84V76.2751H145.047C143.224 76.2751 142.514 75.5471 142.514 73.6797V60.766H156.067V50.9541H142.514V40.9524H142.502V22.4681H158.207H158.204Z"
          fill="black"
        />
        <path
          d="M82.5521 43.6151C85.9433 46.5966 90.6771 48.1095 96.6237 48.1095H108.339C114.286 48.1095 119.023 46.5966 122.417 43.6088C125.85 40.5449 127.591 36.0505 127.591 30.2583C127.591 24.4661 125.85 19.9242 122.421 16.9078C119.029 13.8788 114.292 12.3438 108.343 12.3438H96.6269C90.674 12.3438 85.937 13.8788 82.5553 16.9015C79.1197 19.9242 77.375 24.4187 77.375 30.2583C77.375 36.0979 79.1165 40.5449 82.5553 43.6151H82.5521ZM89.4042 30.2583C89.4042 27.4002 90.0122 25.3081 91.1996 24.0515C92.4408 22.776 94.214 22.1556 96.6237 22.1556H108.339C110.743 22.1556 112.491 22.7728 113.7 24.0515C114.932 25.3176 115.559 27.4065 115.559 30.2583C115.559 33.1101 114.935 35.1357 113.688 36.4144C112.491 37.6805 110.743 38.2977 108.339 38.2977H96.6237C94.214 38.2977 92.4408 37.6773 91.2123 36.4144C90.0122 35.1452 89.4042 33.0721 89.4042 30.2583Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_12566_572">
          <rect width="294.376" height="86.0911" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function IconMoon() {
  return (
    <svg
      width="295"
      height="91"
      viewBox="0 0 295 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M233.43 89.2786V1.35156H253.01L276.039 52.8271V1.35156H295.003V89.2786H275.915L252.394 37.3105V89.2786H233.43Z"
        fill="black"
      />
      <path
        d="M159.305 58.1255V32.5109C159.305 20.5656 161.029 14.0388 165.339 8.74345C170.388 2.58609 177.654 0 189.599 0C202.406 0 210.164 3.07868 214.967 10.2212C218.538 15.3934 219.893 21.6739 219.893 32.5109V58.1255C219.893 70.0707 218.169 76.5975 213.859 81.8929C208.81 88.0502 201.544 90.6363 189.599 90.6363C176.792 90.6363 169.033 87.5576 164.107 80.4151C160.659 75.2429 159.305 68.9624 159.305 58.1255ZM199.943 56.5246V34.1118C199.943 19.8267 197.85 16.1323 189.599 16.1323C181.348 16.1323 179.255 19.8267 179.255 34.1118V56.5246C179.255 70.8096 181.348 74.504 189.599 74.504C197.85 74.504 199.943 70.8096 199.943 56.5246Z"
        fill="black"
      />
      <path
        d="M86.9062 58.1255V32.5109C86.9062 20.5656 88.6303 14.0388 92.9405 8.74345C97.9895 2.58609 105.255 0 117.2 0C130.008 0 137.766 3.07868 142.569 10.2212C146.14 15.3934 147.495 21.6739 147.495 32.5109V58.1255C147.495 70.0707 145.771 76.5975 141.46 81.8929C136.411 88.0502 129.146 90.6363 117.2 90.6363C104.393 90.6363 96.6349 87.5576 91.709 80.4151C88.2609 75.2429 86.9062 68.9624 86.9062 58.1255ZM127.545 56.5246V34.1118C127.545 19.8267 125.451 16.1323 117.2 16.1323C108.95 16.1323 106.856 19.8267 106.856 34.1118V56.5246C106.856 70.8096 108.95 74.504 117.2 74.504C125.451 74.504 127.545 70.8096 127.545 56.5246Z"
        fill="black"
      />
      <path
        d="M0 89.2786V1.35156H18.4721L36.6979 45.6845L54.9236 1.35156H73.3957V89.2786H54.3079V41.4975L43.1015 69.9445H30.2942L19.0878 41.4975V89.2786H0Z"
        fill="black"
      />
    </svg>
  )
}
