import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import { isDevEnvironment } from '@constants/index'

import { deepCloneWithKeys, getUserLanguage } from '../utils'

import nsHome from './locales/en/nsHome.json'
import nsHeader from './locales/en/nsHeader.json'
import nsFooter from './locales/en/nsFooter.json'
import nsBlog from './locales/en/nsBlog.json'
import nsRoadMap from './locales/en/nsRoadMap.json'
import nsHomeJa from './locales/ja/nsHome.json'
import nsHeaderJa from './locales/ja/nsHeader.json'
import nsFooterJa from './locales/ja/nsFooter.json'
import nsBlogJa from './locales/ja/nsBlog.json'
import nsRoadMapJa from './locales/ja/nsRoadMap.json'

const nsHomeKeys = deepCloneWithKeys<typeof nsHome>(nsHome, 'nsHome')
const nsHeaderKeys = deepCloneWithKeys<typeof nsHeader>(nsHeader, 'nsHeader')
const nsFooterKeys = deepCloneWithKeys<typeof nsFooter>(nsFooter, 'nsFooter')
const nsBlogKeys = deepCloneWithKeys<typeof nsBlog>(nsBlog, 'nsBlog')
const nsRoadMapKeys = deepCloneWithKeys<typeof nsRoadMap>(nsRoadMap, 'nsRoadMap')

const isWithKey = false

export const resources = {
  en: {
    nsHome: isWithKey ? nsHomeKeys : nsHome,
    nsHeader: isWithKey ? nsHeaderKeys : nsHeader,
    nsFooter: isWithKey ? nsFooterKeys : nsFooter,
    nsBlog: isWithKey ? nsBlogKeys : nsBlog,
    nsRoadMap: isWithKey ? nsRoadMapKeys : nsRoadMap,
  },
  ja: {
    nsHome: nsHomeJa,
    nsHeader: nsHeaderJa,
    nsFooter: nsFooterJa,
    nsBlog: nsBlogJa,
    nsRoadMap: nsRoadMapJa,
  },
}

export const i18nextInit = (isWithKey) => {
  const resource = {
    en: {
      nsHome: isWithKey ? nsHomeKeys : nsHome,
      nsHeader: isWithKey ? nsHeaderKeys : nsHeader,
      nsFooter: isWithKey ? nsFooterKeys : nsFooter,
      nsBlog: isWithKey ? nsBlogKeys : nsBlog,
      nsRoadMap: isWithKey ? nsRoadMapKeys : nsRoadMap,
    },
    ja: {
      nsHome: nsHomeJa,
      nsHeader: nsHeaderJa,
      nsFooter: nsFooterJa,
      nsBlog: nsBlogJa,
      nsRoadMap: nsRoadMapJa,
    },
  }

  i18next.use(initReactI18next).init({
    lng: localStorage.getItem('language') || getUserLanguage(),
    debug: isDevEnvironment,
    resources: resource,
  })
}

i18nextInit(isWithKey)
