export const getUserLanguage = (): string => {
  const userLang = navigator.language || navigator.languages[0]
  return userLang.startsWith('ja') ? 'ja' : 'en'
}

export const deepCloneWithKeys = <T>(obj, keyMessage: string): T => {
  const message = keyMessage ? `${keyMessage}.` : ''

  if (obj === null) return {} as T
  const clone = { ...obj }

  const fn = (key) => {
    if (key === 'envs') {
      clone[key] = obj[key]
    } else if (typeof obj[key] === 'object') {
      clone[key] = deepCloneWithKeys(obj[key], `${message}${key}`)
    } else {
      clone[key] = `${message}${key}`
    }

    return null
  }

  Object.keys(clone).forEach((key) => fn(key))

  const newObj =
    Array.isArray(obj) && obj.length
      ? (clone.length = obj.length) && Array.from(clone)
      : Array.isArray(obj)
      ? Array.from(obj)
      : clone

  return newObj
}
