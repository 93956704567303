import React, { PropsWithChildren } from 'react'

import cn from 'classnames'

import styles from './styles.module.scss'

interface Props {
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'
  className?: string
  uppercase?: boolean
}

function Typography({
  component,
  children,
  className,
  uppercase,
  ...props
}: PropsWithChildren<Props>) {
  const tagName = component ?? 'p'

  return React.createElement(
    tagName,
    {
      className: cn(
        styles.typography,
        styles[`typography--${tagName}`],
        {
          [styles['typography--uppercase']]: uppercase,
        },
        className,
      ),
      ...props,
    },
    children,
  )
}

Typography.defaultProps = {
  component: 'p',
}

export default Typography
