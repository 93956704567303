import React from 'react'

import { useTranslation } from 'react-i18next'

import Container from '@components/Container'
import Typography from '@components/UiKit/Typography'

import styles from './faq.module.scss'
import Question from './Question'

function FAQ() {
  const { t } = useTranslation('nsHome')
  const faq = t('faq', { returnObjects: true })
  return (
    <section className={styles.section}>
      <Container>
        <div className={styles.title_wrap}>
          <Typography uppercase component="h2">
            {faq.title}
          </Typography>
        </div>

        <div className={styles.faq_container}>
          {faq.content.map((f, index) => (
            <Question {...f} index={index + 1} key={f.id} />
          ))}
        </div>
      </Container>
    </section>
  )
}

export default FAQ
