import useCurrentWidth from '@hooks/useCurrentWidth'

export const useSwiperHeight = () => {
  const { isLarge, isTablet, isLaptop, isDesktop, isFullHd } = useCurrentWidth()

  let height: number

  if (isLarge) {
    height = 500
  } else if (isDesktop || isFullHd) {
    height = 350
  } else if (isTablet || isLaptop) {
    height = 300
  } else {
    height = 200
  }

  return {
    height,
  }
}
