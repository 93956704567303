import React, { Fragment, memo } from 'react'

import { useTranslation } from 'react-i18next'

import Container from '@components/Container'
import Badge from '@components/Badge'

import styles from './community.module.scss'

function Community() {
  const { t } = useTranslation('nsRoadMap')

  const { title, subTitle, first_article, second_article, third_article } = t('community', {
    returnObjects: true,
  })

  return (
    <section className={styles.section}>
      <Container>
        <div className={styles.title}>
          <div className={styles.top_line} />
          <p>{title}</p>
          <p>{subTitle}</p>
        </div>

        <div className={styles.articles}>
          <div className={styles.first_article}>
            <p>{first_article.text}</p>
            <br />
            <p>
              {first_article.ending.start}
              <span className={styles.italic}>{first_article.ending.end}</span>
            </p>
          </div>
          <div className={styles.second_article}>
            {second_article.map((p, i) => (
              <Fragment key={p}>
                <p>{p}</p>
                {i < second_article.length - 1 && <br />}
              </Fragment>
            ))}
          </div>
          <div className={styles.third_article}>
            <Badge title={third_article.badge} className={styles.itemBadge} />

            <div className={styles.features}>
              {third_article.features.map((p) => (
                <p key={p}>{p}</p>
              ))}
            </div>

            <p className={styles.vinegoat}>{third_article.hashtag}</p>

            <a
              href="https://twitter.com/vine_dao"
              target="_blank"
              rel="noreferrer"
              className={styles.x}
            >
              {third_article.x_social}
            </a>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default memo(Community)
