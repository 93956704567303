/* eslint-disable max-len */
import React from 'react'

import cn from 'classnames'

import { TIconProps } from '@components/Icons/types'

import styles from '../styles.module.scss'

function Link({ width = '25', size, color = '#fff' }: TIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 24"
      width={width}
      className={cn({ [styles.scaled]: size === 'sm' })}
      color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2651 3.22556C15.5779 0.924813 19.3276 0.924811 21.6404 3.22556C23.9532 5.52632 23.9532 9.25657 21.6404 11.5573L18.3048 14.8756C16.1285 17.0405 12.6799 17.1683 10.3534 15.2589C10.1094 15.0586 10.1109 14.6952 10.3346 14.4726L11.5497 13.2638C11.7734 13.0413 12.1338 13.0469 12.3951 13.2244C13.5856 14.0331 15.223 13.912 16.2796 12.8609L19.6152 9.54262C20.8095 8.35456 20.8095 6.42833 19.6152 5.24027C18.4209 4.05221 16.4846 4.05221 15.2903 5.24027L14.4228 6.1033C14.2655 6.25974 14.032 6.3096 13.8195 6.24452C13.072 6.01565 12.2953 5.90705 11.5201 5.9187C11.1872 5.92371 10.9796 5.49918 11.215 5.26497L13.2651 3.22556ZM15.3966 8.74115C13.0701 6.8317 9.62146 6.95947 7.44517 9.12444L4.10959 12.4427C1.79681 14.7434 1.7968 18.4737 4.10958 20.7744C6.42236 23.0752 10.1721 23.0752 12.4849 20.7744L14.5322 18.7378C14.7676 18.5036 14.5602 18.0792 14.2274 18.0841C13.4523 18.0955 12.6758 17.9867 11.9285 17.7576C11.716 17.6925 11.4824 17.7423 11.3251 17.8988L10.4597 18.7597C9.26539 19.9478 7.32909 19.9478 6.13482 18.7597C4.94055 17.5717 4.94055 15.6454 6.13482 14.4574L9.47041 11.1391C10.527 10.088 12.1644 9.96687 13.3549 10.7756C13.6162 10.9531 13.9766 10.9587 14.2003 10.7362L15.4154 9.52739C15.6391 9.30485 15.6406 8.94144 15.3966 8.74115Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Link
