/* eslint-disable max-len */
import React from 'react'

import cn from 'classnames'

import { TIconProps } from '@components/Icons/types'

import styles from '../../styles.module.scss'

function Instagram({ width = '22', size, color = '#fff' }: TIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 66.5 66.5"
      width={width}
      className={cn({ [styles.scaled]: size === 'sm' })}
      color={color}
    >
      <g>
        <path
          fillRule="evenodd"
          fill="currentColor"
          d="M33.3,6c8.9,0,9.9,0,13.4,0.2c3.2,0.1,5,0.7,6.2,1.1c1.6,0.6,2.7,1.3,3.8,2.5c1.2,1.2,1.9,2.3,2.5,3.8
		c0.5,1.2,1,2.9,1.1,6.2c0.2,3.5,0.2,4.6,0.2,13.4s0,9.9-0.2,13.4c-0.1,3.2-0.7,5-1.1,6.2c-0.6,1.6-1.3,2.7-2.5,3.8
		c-1.2,1.2-2.3,1.9-3.8,2.5c-1.2,0.5-2.9,1-6.2,1.1c-3.5,0.2-4.6,0.2-13.4,0.2s-9.9,0-13.4-0.2c-3.2-0.1-5-0.7-6.2-1.1
		c-1.6-0.6-2.7-1.3-3.8-2.5c-1.2-1.2-1.9-2.3-2.5-3.8c-0.5-1.2-1-2.9-1.1-6.2C6,43.2,6,42.2,6,33.3s0-9.9,0.2-13.4
		c0.1-3.2,0.7-5,1.1-6.2c0.6-1.6,1.3-2.7,2.5-3.8c1.2-1.2,2.3-1.9,3.8-2.5c1.2-0.5,2.9-1,6.2-1.1C23.3,6,24.4,6,33.3,6 M33.3,0
		c-9,0-10.2,0-13.7,0.2c-3.5,0.2-6,0.7-8.1,1.5c-2.2,0.9-4,2-5.9,3.8c-1.8,1.8-3,3.7-3.8,5.9c-0.8,2.1-1.4,4.5-1.5,8.1
		C0,23.1,0,24.2,0,33.3c0,9,0,10.2,0.2,13.7c0.2,3.5,0.7,6,1.5,8.1c0.9,2.2,2,4,3.8,5.9c1.8,1.8,3.7,3,5.9,3.8
		c2.1,0.8,4.5,1.4,8.1,1.5c3.5,0.2,4.7,0.2,13.7,0.2s10.2,0,13.7-0.2c3.5-0.2,6-0.7,8.1-1.5c2.2-0.9,4-2,5.9-3.8
		c1.8-1.8,3-3.7,3.8-5.9c0.8-2.1,1.4-4.5,1.5-8.1c0.2-3.5,0.2-4.7,0.2-13.7s0-10.2-0.2-13.7c-0.2-3.5-0.7-6-1.5-8.1
		c-0.9-2.2-2-4-3.8-5.9c-1.8-1.8-3.7-3-5.9-3.8c-2.1-0.8-4.5-1.4-8.1-1.5C43.4,0,42.3,0,33.3,0L33.3,0z"
        />
        <path
          fillRule="evenodd"
          fill="currentColor"
          d="M33.3,16.2c-9.4,0-17.1,7.6-17.1,17.1s7.6,17.1,17.1,17.1s17.1-7.6,17.1-17.1S42.7,16.2,33.3,16.2z M33.3,44.4
		c-6.1,0-11.1-5-11.1-11.1s5-11.1,11.1-11.1c6.1,0,11.1,5,11.1,11.1S39.4,44.4,33.3,44.4z"
        />
        <circle cx="51" cy="15.5" r="4" />
      </g>
    </svg>
  )
}

export default Instagram
