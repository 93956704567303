import React from 'react'

import { NavLink } from 'react-router-dom'

import whitePaperAsset from '@assets/images/whitePaper.png'
import SvgImage from '@components/SvgImage'
import Download from '@components/Icons/Download'

import styles from './white_paper.module.scss'

interface PropsNewsItemCard {
  titleLink?: string
  title: string
  subTitle?: string
  readMore?: string
  link: string
  readMoreLink?: string
  actionLink?: string
}

export function NewsItemCard({
  titleLink,
  title,
  subTitle,
  readMore,
  readMoreLink,
  link,
  actionLink,
}: Readonly<PropsNewsItemCard>) {
  return (
    <div className={styles.flexItem}>
      <div className={styles.itemBadge}>{title}</div>
      {(subTitle && titleLink && (
        <NavLink to={titleLink} className={styles.itemHeading}>
          {subTitle}
        </NavLink>
      )) || <span className={styles.itemHeading}>{subTitle}</span>}
      {(readMoreLink && readMore && (
        <NavLink to={readMoreLink} className={styles.link}>
          {readMore}
        </NavLink>
      )) || <span className={styles.link}>{readMore}</span>}
      <div className={styles.swiperLine} />
      {actionLink && link && (
        <a target="_blank" href={actionLink} className={styles.link} rel="noreferrer">
          {link}
        </a>
      )}
    </div>
  )
}
interface PropsCardImage {
  title: string
  link: string
  onDownload: () => void
}

export function CardImage({ title, link, onDownload }: PropsCardImage) {
  return (
    <div data-title={title} className={styles.whitePaperImage} onClick={onDownload}>
      <img src={whitePaperAsset} alt={title} />
      <div className={styles.downloadLink}>
        {link}
        <SvgImage src={<Download />} color="#000" />
      </div>
    </div>
  )
}
