import React, { Suspense } from 'react'

import { Route, Routes, useLocation, Navigate } from 'react-router-dom'

import { LocalizationProvider } from '@context/LocalizationProvider'
import scheme from '@routing/scheme'
import ROUTING from '@routing/path'
import Header from '@components/Header'
import Footer from '@components/Footer'

import './i18n/config'
import styles from './app.module.scss'

function App() {
  const { pathname } = useLocation()
  const isComingSoon = pathname?.includes('coming-soon')

  return (
    <LocalizationProvider>
      <main id="main" className={styles.main}>
        {!isComingSoon && <Header />}
        <div className={styles.container}>
          <Suspense fallback="">
            <Routes>
              {scheme.map((item, i) => (
                <Route key={item.path || i} {...item} />
              ))}
              <Route path="*" element={<Navigate to={ROUTING.home} replace />} />
            </Routes>
          </Suspense>
        </div>
        {!isComingSoon && <Footer />}
      </main>
    </LocalizationProvider>
  )
}

export default App
