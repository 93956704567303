import React, { useState } from 'react'

import cn from 'classnames'

import styles from './question.module.scss'

interface IQuestion {
  question: string
  answer: string
  index: number
}

function Question({ question, answer, index }: IQuestion) {
  const [active, setActive] = useState<boolean>(false)

  const onExpand = () => setActive(!active)

  return (
    <div className={cn(styles.question, { [styles.active]: active })}>
      <div className={styles.question__header}>
        <p className={styles.question__id}>[{index.toString().padStart(2, '0').slice(-2)}]</p>
        <p className={styles.question__title} onClick={onExpand}>
          {question}
        </p>

        <div className={styles.question__expand} onClick={onExpand}>
          ↳
        </div>
      </div>

      <div className={styles.question__body}>
        <p className={styles.question__text}>{answer}</p>
      </div>
    </div>
  )
}

export default React.memo(Question)
