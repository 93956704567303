import React, { useContext } from 'react'

import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import LogotypeImage from '@assets/images/vine_logo.svg'
import XSocialImage from '@assets/images/x_social.svg'
import ROUTING from '@routing/path'
import { REACT_APP_STAGE } from '@constants/environments'

import { LocalizationContext } from '../../context/LocalizationProvider'

import LangSelect from './LangSelect'
import Hamburger from './Hamburger'
import styles from './header.module.scss'

function Header() {
  const { t } = useTranslation('nsHeader')
  const headerLinks = t('headerLinks', { returnObjects: true })

  const localizationContext = useContext(LocalizationContext)
  if (!localizationContext) return null

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={cn(styles.side, styles.left)}>
          <NavLink to={ROUTING.home} className={styles.logotype}>
            <img src={LogotypeImage} alt="Vine" />
          </NavLink>
        </div>

        <div className={cn(styles.side, styles.right)}>
          {headerLinks?.map(({ id, to, name, envs }) => {
            if (envs.includes(REACT_APP_STAGE as string)) {
              return (
                <NavLink
                  key={id}
                  to={to}
                  className={({ isActive }) => cn(styles.header_link, isActive && styles.active)}
                >
                  {name}
                </NavLink>
              )
            }
            return null
          })}
          <a
            className={styles.button}
            href="https://twitter.com/vine_dao"
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.icon}>
              <img src={XSocialImage} alt="XSocial" />
            </div>
            {t('followUs')}
          </a>
          <LangSelect />
          <Hamburger />
        </div>
      </div>
    </header>
  )
}

export default React.memo(Header)
