import React, { useContext, useState } from 'react'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { isDevEnvironment } from '@constants/environments'
import { getUserLanguage } from '@utils/index'

import { LocalizationContext } from '../../context/LocalizationProvider'

import styles from './header.module.scss'

interface ILangSelect {
  className?: string
}

const languages = ['en', 'ja']

function LangSelect({ className }: ILangSelect) {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem('language')
    return storedLanguage || getUserLanguage()
  })

  const changeLanguage = (lang: string) => {
    if (language !== lang) {
      setLanguage(lang)
      i18n.changeLanguage(lang)
      localStorage.setItem('language', lang)
    }
  }

  const localizationContext = useContext(LocalizationContext)
  if (!localizationContext) return null
  const { isWithKey, toggleIsWithKey } = localizationContext

  return (
    <div className={cn(styles.langSelector, className)}>
      {languages.map((lang, index) => (
        <React.Fragment key={lang}>
          <button
            type="button"
            className={cn(styles.lang, { [styles.active]: lang === language })}
            onClick={() => changeLanguage(lang)}
          >
            {lang}
          </button>
          {index !== languages.length - 1 && <span className={styles.separator}>/</span>}
        </React.Fragment>
      ))}
      {isDevEnvironment ? (
        <button
          type="button"
          onClick={toggleIsWithKey}
          className={cn(styles.lang, styles.keyBtn, styles.hiddenButton, {
            [styles.key_active]: !isWithKey,
          })}
        >
          lang keys
        </button>
      ) : null}
    </div>
  )
}

export default React.memo(LangSelect)
