import React from 'react'

import { useTranslation } from 'react-i18next'

import Container from '@components/Container'

import styles from './platform.module.scss'

function OpenPlatform() {
  const { t } = useTranslation('nsHome')
  const about = t('about', { returnObjects: true })
  const aboutCards = t('about.cards', { returnObjects: true })
  const vine = t('vine', { returnObjects: true })
  const flexible = t('flexible', { returnObjects: true })
  const flexibleSections = t('flexible.sections', { returnObjects: true })

  return (
    <section id="aboutSection" className={styles.section}>
      <Container>
        <div className={styles.title_wrapper}>
          <h1 className={styles.title}>{about.title}</h1>
          <p className={styles.subtitle}>{about.subtitle}</p>
        </div>
        <div className={styles.options_wrapper}>
          {aboutCards.map((option) => (
            <div key={option.id} className={styles.option}>
              <h4 className={styles.option__title}>{option.title}</h4>
              <p className={styles.option__description}>{option.content}</p>
            </div>
          ))}
        </div>
        <div className={styles.vine}>
          <div className={styles.vine_title}>
            <h2 className={styles.vine_title__first}>{vine.title}</h2>
            <h2 className={styles.vine_title__second}>{vine.subtitle}</h2>
          </div>
          <div className={styles.vine_text}>
            <p className={styles.vine_text__content}>{vine.content.first}</p>
            <p className={styles.vine_text__content}>{vine.content.second}</p>
          </div>
        </div>
        <h2 className={styles.descriptors}>{flexible.title}</h2>
        <div className={styles.transactions}>
          {flexibleSections.map((descriptor) => (
            <div key={descriptor.id} className={styles.transactions_item}>
              <div className={styles.amount}>
                {descriptor.blocks.map((amount) => (
                  <div key={amount.id} className={styles.amount_item}>
                    <span className={styles.amount_item__name}>{amount.title}</span>
                    <h3 className={styles.amount_item__number}>{amount.value}</h3>
                  </div>
                ))}
              </div>
              <p className={styles.amount_text}>{descriptor.description}</p>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}

export default OpenPlatform
