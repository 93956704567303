/* eslint-disable max-len */
import React from 'react'

import cn from 'classnames'

import { TIconProps } from '@components/Icons/types'

import styles from '../../styles.module.scss'

function XSocial({ width = '22', size, color = '#fff' }: TIconProps) {
  return (
    <svg
      width={width}
      height={22.5}
      viewBox="0 0 26 23"
      xmlns="http://www.w3.org/2000/svg"
      className={cn({ [styles.scaled]: size === 'sm' })}
      color={color}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        id="Vector"
        d="M19.7703 0.25H23.5873L15.2484 9.78077L25.0584 22.75H17.3773L11.3611 14.8842L4.47727 22.75H0.658042L9.57727 12.5558L0.166504 0.25H8.04266L13.4807 7.43962L19.7703 0.25ZM18.4307 20.4654H20.5457L6.89343 2.41462H4.62381L18.4307 20.4654Z"
      />
    </svg>
  )
}

export default XSocial
