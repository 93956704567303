const ROUTING = {
  home: '/',
  comingSoon: '/coming-soon',
  blog: {
    vineGoLive: '/blog/vine-mainnet-go-live',
  },
  roadmap: '/roadmap',
}

export default ROUTING
