import React from 'react'

/* eslint-disable max-len */
export function IconPeer() {
  return (
    <svg
      width="300"
      height="83"
      viewBox="0 0 300 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13784_514)">
        <path
          d="M132.111 4.36779C127.002 1.74138 121.257 0.414062 114.905 0.414062C108.553 0.414062 102.381 1.81905 97.1566 4.59372C91.9174 7.38956 87.7911 11.2444 84.7777 16.1584C81.7642 21.0723 80.2539 26.7205 80.2539 33.0958C80.2539 39.4712 81.7787 44.9853 84.8427 49.9062C87.8923 54.8201 92.2282 58.7033 97.8287 61.5344C103.429 64.3656 109.926 65.7776 117.333 65.7776C123.208 65.7776 128.404 64.8951 132.906 63.123C136.476 61.718 139.554 59.84 142.17 57.4889C143.312 56.4511 143.37 54.7072 142.322 53.5987L135.956 46.8633C134.958 45.8042 133.303 45.6913 132.14 46.5738C130.507 47.8093 128.765 48.7837 126.915 49.5038C124.299 50.5275 121.264 51.0359 117.795 51.0359C113.929 51.0359 110.562 50.351 107.707 48.9743C104.853 47.5975 102.634 45.5854 101.073 42.959C100.221 41.5328 99.6136 39.9796 99.2234 38.2992H145.791C147.251 38.2992 148.472 37.2119 148.595 35.7858V35.7575C148.674 34.8609 148.725 34.049 148.725 33.3429C148.725 26.5086 147.236 20.6204 144.259 15.6994C141.282 10.7855 137.235 7.00831 132.126 4.37485H132.097L132.111 4.36779ZM106.515 16.3984C108.972 15.0217 111.805 14.3368 115.028 14.3368C118.251 14.3368 121.069 15.0287 123.49 16.3984C125.911 17.7752 127.797 19.6814 129.17 22.1243C130.073 23.741 130.68 25.5485 130.984 27.5465H98.9994C99.3101 25.5767 99.9027 23.7834 100.784 22.1737C102.157 19.6955 104.072 17.7752 106.515 16.3984Z"
          fill="white"
        />
        <path
          d="M208.322 4.36779C203.213 1.74138 197.468 0.414062 191.116 0.414062C184.764 0.414062 178.592 1.81905 173.368 4.59372C168.128 7.38956 164.002 11.2444 160.989 16.1584C157.975 21.0723 156.465 26.7205 156.465 33.0958C156.465 39.4712 157.99 44.9853 161.054 49.9062C164.103 54.8201 168.439 58.7033 174.04 61.5344C179.64 64.3656 186.137 65.7776 193.544 65.7776C199.419 65.7776 204.615 64.8951 209.117 63.123C212.687 61.718 215.765 59.84 218.381 57.4889C219.523 56.4511 219.581 54.7072 218.533 53.5987L212.167 46.8633C211.169 45.8042 209.514 45.6913 208.351 46.5738C206.718 47.8093 204.976 48.7837 203.126 49.5038C200.51 50.5275 197.475 51.0359 194.006 51.0359C190.14 51.0359 186.773 50.351 183.918 48.9743C181.057 47.5975 178.845 45.5854 177.284 42.959C176.432 41.5328 175.825 39.9796 175.434 38.2992H222.002C223.462 38.2992 224.683 37.2119 224.806 35.7858V35.7575C224.885 34.8609 224.936 34.049 224.936 33.3429C224.936 26.5086 223.447 20.6204 220.47 15.6994C217.492 10.7855 213.446 7.00831 208.337 4.37485H208.308L208.322 4.36779ZM182.726 16.3984C185.183 15.0217 188.016 14.3368 191.239 14.3368C194.462 14.3368 197.28 15.0287 199.701 16.3984C202.122 17.7752 204.008 19.6814 205.381 22.1243C206.284 23.741 206.891 25.5485 207.195 27.5465H175.203C175.514 25.5767 176.106 23.7834 176.988 22.1737C178.361 19.6955 180.276 17.7752 182.719 16.3984H182.733H182.726Z"
          fill="white"
        />
        <path
          d="M274.47 0C251.396 0 232.68 18.2719 232.68 40.8293V61.5016C232.68 63.0196 233.944 64.2551 235.498 64.2551H249.272C250.825 64.2551 252.09 63.0196 252.09 61.5016V40.8293C252.09 28.7634 262.113 18.9638 274.47 18.9638H278.568C280.122 18.9638 281.386 17.7282 281.386 16.2103V2.75349C281.386 1.23554 280.122 0 278.568 0H274.47Z"
          fill="white"
        />
        <path
          d="M56.1497 4.00847C50.6793 1.62211 44.161 0.421875 36.5949 0.421875H2.81833C1.26463 0.421875 0 1.65035 0 3.17536V80.2518C0 81.7698 1.26463 83.0053 2.81833 83.0053H16.7365C18.2902 83.0053 19.5548 81.7698 19.5548 80.2518V62.9896C19.5548 61.4716 20.8195 60.2361 22.3732 60.2361H36.5732C44.1393 60.2361 50.6576 59.0359 56.1281 56.6354C61.5985 54.2349 65.826 50.7966 68.8033 46.3133C71.7806 41.8301 73.2693 36.5208 73.2693 30.3925C73.2693 24.2643 71.7806 18.8279 68.8033 14.3446C65.826 9.86139 61.5985 6.42307 56.1281 4.02259H56.1425L56.1497 4.00847ZM49.0244 40.8699C46.0471 43.3833 41.5378 44.6471 35.4965 44.6471H19.5621V15.9897H35.4965C41.5378 15.9897 46.0399 17.2535 49.0244 19.7669C52.0017 22.2803 53.4904 25.8175 53.4904 30.3855C53.4904 34.9534 52.0017 38.3706 49.0244 40.884V40.8699Z"
          fill="white"
        />
        <path
          d="M298.973 52.8463L292.571 49.8033C291.118 49.062 289.926 47.897 289.174 46.4709L286.059 40.2014C285.387 38.86 283.436 38.86 282.764 40.2014L279.736 46.2944C278.963 47.8617 277.677 49.1255 276.072 49.881L269.85 52.8392C269.164 53.171 268.817 53.8135 268.824 54.463C268.824 55.1055 269.164 55.7551 269.85 56.0869L276.253 59.1299C277.706 59.8712 278.898 61.0361 279.65 62.4623L282.764 68.7318C283.436 70.0732 285.387 70.0732 286.059 68.7318L289.087 62.6388C289.861 61.0714 291.147 59.8076 292.751 59.0522L298.973 56.094C299.66 55.7621 300.007 55.1197 299.999 54.4701C299.999 53.8276 299.66 53.1781 298.973 52.8463Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_13784_514">
          <rect width="300" height="83" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function IconFresh() {
  return (
    <svg
      width="300"
      height="62"
      viewBox="0 0 300 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13784_532)">
        <path
          d="M44.9201 0V12.5771H14.3815V23.2648H43.0854V35.96H14.3815V60.8781H0V0H44.9201Z"
          fill="white"
        />
        <path
          d="M75.1618 28.7554C68.5333 28.7554 64.9823 31.1763 64.9823 38.262V60.9363H51.0742V15.7058H64.4496V24.2678C66.5802 18.9535 70.368 15.6468 76.8189 15.3516C77.47 15.3516 78.2393 15.3516 78.9495 15.4106V28.9325C77.4108 28.8144 76.4047 28.7554 75.1618 28.7554Z"
          fill="white"
        />
        <path
          d="M106.942 61.9986C92.2057 61.9986 82.1445 53.0234 82.1445 38.0843C82.1445 23.1453 93.1526 14.4062 106.35 14.4062C121.324 14.4062 130.616 25.8024 129.669 41.8634H96.1118C96.5261 48.2405 101.497 51.252 106.528 51.252C111.559 51.252 115.11 49.4215 116.708 46.7053H129.491C125.467 57.3929 116.057 61.9986 106.942 61.9986ZM115.879 33.4196C115.701 27.8101 111.618 24.9167 106.35 24.9167C101.497 24.9167 96.9995 27.4558 96.2302 33.4196H115.879Z"
          fill="white"
        />
        <path
          d="M156.009 24.624C151.274 24.624 148.788 25.864 148.788 27.9897C148.788 30.1155 150.386 31.2374 154.351 32.005L162.755 33.5402C173.704 35.5478 178.853 38.7364 178.853 46.7078C178.853 56.864 169.502 62.0012 156.955 62.0012C144.409 62.0012 136.36 56.805 134.762 46.2355H147.664C148.847 50.605 152.398 51.9631 157.192 51.9631C161.986 51.9631 165.596 50.605 165.596 47.9478C165.596 45.9402 163.762 44.7002 158.553 43.7555L150.445 42.3974C141.686 40.9802 135.709 36.4926 135.709 28.5212C135.709 19.4278 145.237 14.5859 156.423 14.5859C166.78 14.5859 176.131 18.7783 177.906 29.0526H164.945C164.057 25.6278 160.388 24.624 156.009 24.624Z"
          fill="white"
        />
        <path
          d="M228.568 33.7752V60.8781H214.66V37.2C214.66 30.7638 214.482 25.4495 207.735 25.4495C201.817 25.4495 199.213 29.4057 199.213 37.7314V60.8781H185.305V0H199.213V21.6705C201.225 18.3048 206.196 14.7029 213.239 14.7029C224.957 14.7029 228.568 21.5524 228.568 33.7752Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M297.455 0H270.29C254.015 0 240.758 11.3371 240.758 27.5752V46.6476C252.713 22.4381 281.594 5.37333 297.455 0Z"
          fill="#3FB95A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M246.438 60.9419H270.466C286.741 60.9419 299.998 47.7743 299.998 31.4771V1.42188C274.845 15.3571 257.682 37.0276 246.438 60.9419Z"
          fill="#3FB95A"
        />
      </g>
      <defs>
        <clipPath id="clip0_13784_532">
          <rect width="300" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function IconChi() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="149"
      height="116"
      viewBox="0 0 149 116"
      fill="none"
    >
      <g clipPath="url(#clip0_13784_521)">
        <path
          d="M92.286 65.8262C82.0252 64.1973 71.7612 63.8797 61.3991 65.0166C61.3681 64.6485 61.3338 64.3602 61.3207 64.0719C60.9727 56.213 60.7586 48.3459 60.2472 40.4983C59.6803 31.7695 58.3928 23.1434 55.3897 14.8593C54.063 11.201 52.336 7.74634 50.1891 4.49363C49.6564 3.68737 49.212 2.79479 48.9016 1.88266C48.4686 0.610563 49.029 -0.10611 50.3753 0.0127926C51.3638 0.100748 52.3736 0.317379 53.2983 0.675715C57.9843 2.49183 62.8761 3.29809 67.848 3.7346C73.4457 4.2265 79.0499 4.18578 84.646 3.71506C87.2455 3.4968 89.8319 3.1238 92.4265 2.84365C93.1258 2.76872 93.8414 2.71497 94.5407 2.76873C96.1844 2.89251 97.1288 4.08968 96.7301 5.68428C96.4295 6.88308 95.9001 8.0265 95.4622 9.18946C93.5767 14.1964 92.585 19.402 91.9886 24.694C90.8661 34.6509 91.0181 44.6257 91.6161 54.6053C91.8154 57.9346 92.067 61.2622 92.286 64.5899C92.3105 64.9613 92.2892 65.3359 92.2892 65.8262H92.286Z"
          fill="white"
        />
        <path
          d="M53.8746 66.1028C44.2854 68.0443 35.0197 70.4485 26.5153 75.2827C26.1069 73.6018 25.7229 71.9746 25.3144 70.354C22.7786 60.2799 18.2103 51.1814 12.0849 42.8142C8.97399 38.5663 5.93008 34.2711 2.9025 29.9646C2.12641 28.8603 1.50063 27.6468 0.840543 26.4643C-0.52538 24.0146 -0.224747 21.7343 1.72937 19.6869C3.79623 17.5205 6.34998 16.097 9.09327 14.9878C14.3936 12.8426 19.9422 11.6406 25.5905 10.9304C30.1768 10.3522 34.7877 9.96128 39.3919 9.52151C40.003 9.46287 40.6516 9.52802 41.2513 9.66973C42.2888 9.91568 43.0583 10.5753 43.109 11.6732C43.1809 13.2091 43.1351 14.7565 43.06 16.2941C42.7332 23.0112 43.1237 29.6812 44.2037 36.3234C45.7085 45.5701 48.7459 54.325 52.5447 62.8485C53.0054 63.8844 53.4057 64.9464 53.8779 66.1012L53.8746 66.1028Z"
          fill="white"
        />
        <path
          d="M126.996 77.8253C119.219 73.6605 111.151 70.5543 102.758 68.0981C103.223 66.2494 103.679 64.4463 104.132 62.6416C105.841 55.8039 107.22 48.9059 108.192 41.9216C109.368 33.4584 109.671 24.9593 109.426 16.4309C109.377 14.6962 109.228 12.9648 109.161 11.2301C109.14 10.6682 109.158 10.0851 109.287 9.54106C109.53 8.52143 110.239 8.18264 111.202 8.61101C111.664 8.81624 112.156 9.08011 112.476 9.45147C117.477 15.2402 123.908 18.8611 130.905 21.6593C135.849 23.6367 140.984 24.8551 146.221 25.7297C146.828 25.8307 147.441 26.0164 148 26.2754C149.015 26.7428 149.268 27.497 148.712 28.4742C148.368 29.0785 147.918 29.6454 147.422 30.1356C143.477 34.0415 139.778 38.1444 136.76 42.8289C133.204 48.3505 130.813 54.3559 129.604 60.7701C128.578 66.2234 127.916 71.745 127.093 77.2357C127.07 77.3904 127.042 77.5435 126.995 77.8253H126.996Z"
          fill="white"
        />
        <path
          d="M71.4336 84.6875H77.0983V97.0876H90.666V84.7103H96.3813V115.459H90.7215V102.671H77.1244V115.441H71.4336V84.6875Z"
          fill="white"
        />
        <path
          d="M66.7139 90.5765C65.3888 91.8388 64.1324 93.0343 62.9282 94.181C61.956 93.3145 61.0868 92.4121 60.0934 91.6759C54.1869 87.2961 45.6417 90.0781 43.7105 96.9826C42.2907 102.058 44.2366 106.988 48.5974 109.36C52.3962 111.425 57.9024 110.694 61.2437 107.682C61.8498 107.137 62.4723 106.609 63.1488 106.019C64.2761 107.039 65.5587 108.197 66.9557 109.459C66.2956 110.168 65.7009 110.896 65.0163 111.528C59.5395 116.588 50.8309 117.481 44.4931 113.554C40.0915 110.827 37.804 106.752 37.4397 101.638C37.0884 96.704 38.3367 92.2753 41.9737 88.718C45.4784 85.291 49.7101 83.8267 54.5954 84.1541C58.5951 84.4229 62.0867 85.8578 65.0245 88.5893C65.4395 88.9754 65.8038 89.42 66.178 89.8484C66.3528 90.0487 66.4982 90.2768 66.7172 90.5749L66.7139 90.5765Z"
          fill="white"
        />
        <path d="M103.598 115.431V84.7188H109.236V115.431H103.598Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_13784_521">
          <rect width="149" height="116" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
